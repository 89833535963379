import SmoothScrollAnchors from './src/scripts/smooth-scroll-anchors'

// Enable smooth scrolling anchor links
const onRouteUpdate = () => {
  SmoothScrollAnchors()
}
export { onRouteUpdate }

// Add Cloudinary widget for form image uploads
const addScript = (url) => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript('https://widget.cloudinary.com/v2.0/global/all.js')
  }
}